import React from 'react';

export default function Footer() {
  return (
    <footer id="footer">
{/*       <span class="copyright">
        &copy; Aerial Design: <a href="http://html5up.net">HTML5 UP</a>.
      </span> */}
    </footer>
  );
}
